export const pushConfig = `
{
  "pushServerURL": "__SERVERURL__",
  "android": {
    "senderID": "__SENDERID__",
    "variantID": "__VARIANTID__",
    "variantSecret": "__VARIANT_SECRET__"
  }
}
`;
