export const pushConfig = `
{
  "pushServerURL": "__SERVERURL__",
  "webpush": {
    "variantID": "__VARIANTID__",
    "variantSecret": "__VARIANT_SECRET__",
    "appServerKey": "__VAPID_PUBLIC_KEY__"
  }
}
`;
